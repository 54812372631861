.card{
    width: 350px;
    height: 450px;
    background: hsla(291, 79%, 51%, 1);

    background: linear-gradient(90deg, hsla(291, 79%, 51%, 1) 0%, hsla(240, 91%, 73%, 1) 100%);
    
    background: -moz-linear-gradient(90deg, hsla(291, 79%, 51%, 1) 0%, hsla(240, 91%, 73%, 1) 100%);
    
    background: -webkit-linear-gradient(90deg, hsla(291, 79%, 51%, 1) 0%, hsla(240, 91%, 73%, 1) 100%);
    padding: 3px;
    border-radius: 20px;
    color: white;

    margin-top: 20px;
}

.card-wrapper{
    width: 100%;
    height:100%;

border-radius: 20px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
background-color: rgba(0, 0, 0, 0.922);
padding:20px;
    display: flex;
    flex-direction: column;
    gap: 20px;


 
}


.card-img-container{
    width:120px;
    
}

.card-img{
    border-radius: 50%;
}

.card-heading{
    font-size: 25px;
  
    font-weight: bold;
    
}

.card-text-list{
   list-style: none;
   margin-top: 25px;
   
}


.card-list{
    font-family: 'Raleway';
    font-weight: lighter;
    font-size: 14px;
    text-align: left;
    margin: 5px;

}


.card:hover{
    transform: scale(1.3);
    background-color: red;
}

.card-btn-container{
    position: fixed;
    bottom: 30px;
    left: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}



.landing-button-container{
    
    display: flex;
    flex-wrap: wrap;
    
}

.btn{
    margin: 15px;
    padding: 13px 18px; 
    font-size: .75rem;
    border-radius: 50px;
    transition: .4s ease-out;
    font-weight: bold;
    border: 2px solid  #382bf0 
}

.lighter-btn{
    border: 2px solid #03254E;

}

.custom-btn{
    background-color: #382bf0;
    
    font-family: bold;
}

.btn:hover{
    
    transform: translateY(-5px);
}

.black-feature-text{
    color: rgb(4, 23, 37);
    position: relative

}

.black-feature-text:after{
    content: '';
    position: absolute;
    bottom: 0;
    left:40%;
    width: 20%;
    height: 3px;
    background-color: black;
    border-radius: 20px;;
}


/* cta-container */


.cta-wrapper{
    width: 100%;
    height: 300px;
    
    
    position: relative;
    overflow:hidden;
    
    background: hsla(291, 79%, 51%, 1);

    background: linear-gradient(90deg, hsla(291, 79%, 51%, 1) 0%, hsla(240, 91%, 73%, 1) 100%);
    
    background: -moz-linear-gradient(90deg, hsla(291, 79%, 51%, 1) 0%, hsla(240, 91%, 73%, 1) 100%);
    
    background: -webkit-linear-gradient(90deg, hsla(291, 79%, 51%, 1) 0%, hsla(240, 91%, 73%, 1) 100%);

    z-index: 2;
   
}

.cta-img{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    z-index: -1;
    object-fit: contain;
  
}

.cta-container{
   width: 100%;
   height:100%;
   margin:auto;
   background: rgba( 0, 0, 0, 0.5 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 11.5px );
-webkit-backdrop-filter: blur( 11.5px );

border: 1px solid rgba( 255, 255, 255, 0.18 );
   z-index: 1;

   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   flex-direction: column;
   gap: 20px;
}

.cta-text{
    font-style: normal;
    font-weight: light;
    text-align: center;
    color: whitesmoke;
    font-size: 20px;
    padding: 5px;
}

.cta-btn-container{
    display: flex;
    flex-wrap: wrap;
}

/* Workflow Section */







.intro-container{
    width: 95%;
    margin: auto;
    margin-top:8%;
}


.video-wrapper{
 

}

.video{
    width: 80%;
    margin: auto;
    margin-top: 20px;
}

.section-btn{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}







/* Media Queries */




/* Form Styles */

.form-btn-container{
    text-align: center;
}


@media screen and (max-width: 500px){
    .landing-text{
        height: 55px;
        font-size: 25px;
    }
}
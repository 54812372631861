
*{
    scroll-behavior: smooth;
    font-family: 'Raleway';
}

body{
   
    font-family: 'Raleway';
    background-color: #1a1625;
    scroll-behavior: smooth;
}




.footer-heading{
    color: whitesmoke;
}


.glassmorphism-bg{background: rgba( 14, 20, 40, 0.8 );
    box-shadow: 0 0 30px rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 7px );
    -webkit-backdrop-filter: blur( 7px );
    border-radius: 10px;
   

}

.custom-bg{
    background-color:#1a1625;;
}

.gradient-bg{
    background: hsla(291, 79%, 51%, 1);

    background: linear-gradient(90deg, hsla(291, 79%, 51%, 1) 0%, hsla(240, 91%, 73%, 1) 100%);
    
    background: -moz-linear-gradient(90deg, hsla(291, 79%, 51%, 1) 0%, hsla(240, 91%, 73%, 1) 100%);
    
    background: -webkit-linear-gradient(90deg, hsla(291, 79%, 51%, 1) 0%, hsla(240, 91%, 73%, 1) 100%);
}


.gradient-bg-button{
    background: hsla(291, 79%, 51%, 1);

    background: linear-gradient(90deg, hsla(291, 79%, 51%, 1) 0%, hsla(240, 91%, 73%, 1) 100%);
    
    background: -moz-linear-gradient(90deg, hsla(291, 79%, 51%, 1) 0%, hsla(240, 91%, 73%, 1) 100%);
    
    background: -webkit-linear-gradient(90deg, hsla(291, 79%, 51%, 1) 0%, hsla(240, 91%, 73%, 1) 100%);
    
    font-weight: normal;
    border:none; 
    transition: .3s ease-in-out;
    font-weight: bold;
    color: white;    
    box-shadow: 2px 4px 2px black;
    cursor: pointer;
}

.gradient-bg-button:hover{
    transform: translateY(-3px)
}

.gradient-border-button{
    background: hsla(291, 79%, 51%, 1);

    background: linear-gradient(90deg, hsla(291, 79%, 51%, 1) 0%, hsla(240, 91%, 73%, 1) 100%);
    
    background: -moz-linear-gradient(90deg, hsla(291, 79%, 51%, 1) 0%, hsla(240, 91%, 73%, 1) 100%);
    
    background: -webkit-linear-gradient(90deg, hsla(291, 79%, 51%, 1) 0%, hsla(240, 91%, 73%, 1) 100%);
    
   
    transition: .3s ease-in-out; ;
    padding: 3px;

    box-shadow: 2px 4px 2px black;
    cursor: pointer;
}


.gradient-border-button-container{
    
   background: #1a1625;
   width: 100%;
   height: 100%;
   padding: 8px;
   border-radius: 8px;

 
}


.gradient-border-button:hover{
    transform: translateY(-3px)
}

.custom-heading{
    color: white;

}

.custom-gradient-text{
    background: hsla(291, 79%, 51%, 1);

    background: linear-gradient(90deg, hsla(291, 79%, 51%, 1) 0%, hsla(240, 91%, 73%, 1) 100%);
    
    background: -moz-linear-gradient(90deg, hsla(291, 79%, 51%, 1) 0%, hsla(240, 91%, 73%, 1) 100%);
    
    background: -webkit-linear-gradient(90deg, hsla(291, 79%, 51%, 1) 0%, hsla(240, 91%, 73%, 1) 100%);
    
    background-clip: text;
    color: transparent;
}


.logo-banner-wrapper{
    width: 100%;
    max-width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 20px;
    background-color: #1a1625;
    color: white;
}

.logo-banner-phone{
    font-size: 10px;

}

.logo-img{
    width: 40px;
    border-radius: 50%;

   
}

.header-links li span {
    position: relative;
    z-index: 0;
  }

  .header-links li span::before {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 2px;
    left: -4px;
    right: -4px;
    display: block;
    height: 6px;
  }

  .header-links li.active span::before {
    background-color: #fcae04;
  }

  .header-links li:not(.active):hover span::before {
    background-color: #ccc;
  }